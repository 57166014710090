import React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <h1
          className="has-text-weight-bold is-size-1"
          style={{
            color: "#4a4a4a",
            fontSize: 40,
            fontWeight: "bold",
            marginTop: 80,
            marginBottom: 60,
            textAlign: "center",
          }}
        >
          Our News
        </h1>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
